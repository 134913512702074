gsap.registerPlugin(ScrollTrigger);
var match_Media = gsap.matchMedia();
var isMobile767 = window.matchMedia("(max-width: 767px)").matches;
var paddingDsk = parseFloat(
  getComputedStyle(document.documentElement).getPropertyValue("--padding-large")
);
var paddingDskSmall = parseFloat(
  getComputedStyle(document.documentElement).getPropertyValue(
    "--padding-medium"
  )
);
var isRTL = $("html").attr("dir") == "rtl" ? true : false,
  winWidth = $(window).width(),
  winHeight = $(window).height(),
  topPos = 0,
  bottomPos = 0;
var assetsURL = isRTL ? "" : "";
var bannerPrev = `<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><img src="${assetsURL}/assets/img/icon/arrow-left.svg" alt="left"class="svg js-tosvg"/></button>`;
var bannerNext = `<button class="slick-next slick-arrow" aria-label="Next" type="button"><img src="${assetsURL}/assets/img/icon/arrow-right.svg" alt="left"class="svg js-tosvg"/</button>`;
$(function () {
    browserDetect();
});
$(window).on("load", function () {
    setTimeout(() => {
      animKeyFact();
    }, 100);
    setTimeout(() => {
      faqRedirect();
    }, 150);
    initializeAOS();
    ChangeToSvg();
});

$(document).ready(function () {
    window.dataLayer = window.dataLayer || [];
});
document.addEventListener('DOMContentLoaded', function() {
    videoPlayerInit();
    handleDataLayer();
    dataSrc();
    scrollTop();
    faqQsAndAnsIds();
    calculateExpandWidth();
    drawerSlider();
    drawer();
    clickVideoPopup();
    sectionAnim();
    sectionAnimVideo();
    closeVideoOnClick();
    setTimeout(() => {
    slickSliderWithCounter();
    testimonialSlider();
    }, 50);
    setTimeout(() => {
    milestone();
    partnerSlider();
    }, 100);
    setInterval(hotfixes, 80);
    setTimeout(() => {
    LazyLoadingFunc()
    }, 4000);

});
function LazyLoadingFunc() {
    const lazyElements = document.querySelectorAll("img[data-src], video[data-src], [data-bg]");

    const loadMedia = (element) => {
      if (element.tagName === "IMG") {
        // Handle lazy loading for images
        const isMobile = window.innerWidth <= 768;
        const dataSrc = isMobile ? element.dataset.mobileSrc : element.dataset.desktopSrc;
        element.src = dataSrc || element.dataset.src;
        element.removeAttribute("data-src data-desktop-src data-mobile-src");

      } else if (element.tagName === "VIDEO") {
        // Handle lazy loading for videos
        const isMobile = window.innerWidth <= 768;
        const dataSrc = isMobile ? element.dataset.mobileSrc : element.dataset.desktopSrc;

        if (dataSrc) {
          element.src = dataSrc;
          element.removeAttribute("data-src data-desktop-src data-mobile-src");
        }

        const sources = element.querySelectorAll("source[data-src], source[data-desktop-src], source[data-mobile-src]");
        sources.forEach((source) => {
          const sourceSrc = isMobile ? source.dataset.mobileSrc : source.dataset.desktopSrc;
          if (sourceSrc) {
            source.src = sourceSrc;
            source.removeAttribute("data-src data-desktop-src data-mobile-src");
          }
        });

        element.load(); // Trigger video loading
      } else if (element.hasAttribute("data-bg")) {
        // Handle lazy loading for background images
        const isMobile = window.innerWidth <= 768;
        const bgUrl = isMobile ? element.dataset.mobileBg : element.dataset.desktopBg;
        const finalBgUrl = bgUrl || element.dataset.bg;

        if (finalBgUrl) {
          element.style.backgroundImage = `url(${finalBgUrl})`;
          element.removeAttribute("data-bg data-desktop-bg data-mobile-bg");
        }
      }
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadMedia(entry.target);
          observer.unobserve(entry.target); // Stop observing once loaded
        }
      });
    });

    lazyElements.forEach((element) => observer.observe(element));
  }
  function videoPlayerInit() {
    $(".my-video-js").each(function (index) {
      var thisId = $(this).attr("id");
      $(this).addClass("video-js");
      if (!thisId == "") {
        thisId = "video-id-" + index;
        $(this).attr("id", thisId);
      }
      const dataSrc = $(this).data("src");
      if (dataSrc) {
        // Apply data-src to the video src
        $(this).attr("src", dataSrc).removeAttr("data-src");

        // Apply data-src to <source> elements inside the video
        $(this)
          .find("source[data-src]")
          .each(function () {
            $(this).attr("src", $(this).data("src")).removeAttr("data-src");
          });

        // Load the video to apply the sources
        this.load();
      }
      var player = videojs(thisId);
    });
  }
  function pageLoadings(){
    var loadingTime = 2000;
    var counter = 0;
    var interval = setInterval(function(){
      counter++;
      if(counter==101){
        clearInterval(interval);
        // LazyLoadingFunc();
      }
    },loadingTime)
  }
  $(function () {
    browserDetect();
  });

  function initializeAOS() {
    AOS.init({
      once: true,
    });
  }

  // Scroll timeline block animation gsap
  function animKeyFact() {
    if ($(".js-kf-trigger").length > 0) {
      const triggerAnim = $(".js-kf-trigger");
      const animItems = $(".js-kf-items");
      const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
      const itemWidth = $(".js-kf-items").width();

      const paddingDskSmPixels = paddingDskSmall * rootFontSize;
      const paddingDskPixels = paddingDsk * rootFontSize;
      const containPadding = winWidth > 1600 ? paddingDskPixels : paddingDskSmPixels;

      // Calculate translateX to move animItems from extreme right to extreme left
      const translateX = isRTL
        ? -animItems.width() + winWidth - containPadding * 2
        : -(animItems.width() + winWidth - containPadding * 2);

      // Set the initial position of animItems to start from the extreme right
      match_Media.add("(min-width: 601px)", () => {
        gsap.set(animItems, { x: isRTL ? -winWidth : winWidth });
      })

      let tl_kf;
      match_Media.add("(min-width: 601px)", () => {
        tl_kf = gsap.timeline({
          lazy:false,
          scrollTrigger: {
            trigger: triggerAnim,
            pin: true,
            scrub: 1,
            start: "0 0",
            end: () => `+=${animItems.width() * 0.8}`, // Increased duration to account for full scroll
          },
        });
        tl_kf.to(animItems, {
          x: isRTL ?  itemWidth : -itemWidth - 70,
        });
      });
      ScrollTrigger.refresh();
    }
  }

  function hotfixes(){
      if($('.js-slider-heighlight-wrapper').length > 0 && $('.js-slider-heighlight-wrapper').find('.slick-track').length == 0 ){
          $('.js-slider-heighlight-wrapper').slick('unslick');
          slickSliderWithCounter();
          initializeAOS();
          ChangeToSvg();
      }
  }

  $(window).on("orientationchange", function () {
    location.reload();
  });
  function browserDetect() {
    navigator.sayswho = (function () {
      var ua = navigator.userAgent,
        tem,
        M =
          ua.match(
            /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
          ) || [];
      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return "IE " + (tem[1] || "");
      }
      if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) return tem.slice(1).join("").replace("OPR", "Opera");
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(" ");
    })();
    $("body").addClass(navigator.sayswho);
  }

  // Change Image to Svg
  function ChangeToSvg() {
    $("img.js-tosvg").each(function () {
      var $img = $(this);
      var imgID = $img.attr("id");
      var imgClass = $img.attr("class");
      var imgURL = $img.attr("src");
      $.get(
        imgURL,
        function (data) {
          var $svg = $(data).find("svg");
          if (typeof imgID !== "undefined") {
            $svg = $svg.attr("id", imgID);
          }
          if (typeof imgClass !== "undefined") {
            $svg = $svg.attr("class", imgClass + " insvg");
          }
          $svg = $svg.removeAttr("xmlns:a");
          if (
            !$svg.attr("viewBox") &&
            $svg.attr("height") &&
            $svg.attr("width")
          ) {
            $svg.attr(
              "viewBox",
              "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
            );
          }
          $img.replaceWith($svg);
        },
        "xml"
      );
    });
  }

  // get mouse position on viewport
  var mouse = { x: 0, y: 0 };
  document.addEventListener("mousemove", function (e) {
    if (winWidth > 767) {
      mouse.x = e.clientX;
      mouse.y = e.clientY;
      mouseText();
    }
  });

  function mouseText() {
    // track .c-mouseTracker element to mouse position
    var mouseTracker = document.querySelector(".c-mouseTracker");
    if (mouseTracker) {
      mouseTracker.style.transform =
        "translate(" + mouse.x + "px, " + mouse.y + "px)";
    }
  }

  // set isRTL based on HTML dir attribute
  var isRTL = $("html").attr("dir") == "rtl" ? true : false;

  $(document).ready(function () {
    $(".menu-link").click(function () {
      $(".menu-link").removeClass("active");
      $(this).addClass("active");
    });
  });
  $(document).ready(function () {
    $(".menuIcon").click(function () {
      // Toggle the "dark-mode" class on the body element
      $("body").toggleClass("menu--open");
    });
  });

  function clickVideoPopup() {
    $("body")
      .on("click", ".js-videoPopup", function () {

        videoPopup($(this));
        separatePopup();
      })
      .on("click touchstart", ".parent-cl", function (e) {
        $(this).parents(".video-box").toggleClass("play-video");
        var etarget = $(e.target);
        if (winWidth < 1300) {
          if (etarget.parents(".video-box").hasClass("play-video")) {
            etarget[0].play();
          } else {
            etarget[0].pause();
          }
        }
      });
  }

  function videoPopup(target) {
    var $target = $(target);
    var videoUrl;
    var vidPlayer = null;
    if (winWidth < 600) {
      videoUrl = $target.data("mobile-url");
    } else {
      videoUrl = $target.data("desktop-url");
    }
    var videoClass = $target.data("video-class");
    var videoWidth = $target.data("width");
    var videoHeight = $target.data("height");
    var videoType = $target.data("video-type");
    var videoPoster = $target.data("video-poster") || null;
    var techOrder = ["html5", "youtube", "wistia"];
    var $content =
      '<div class="popup-video op-0"><video id="lightboxVideo" width="' +
      videoWidth +
      '" height="' +
      videoHeight +
      '" preload="auto" controls autoplay class="video-js vjs-layout-large" data-setup="{}"><source src="' +
      videoUrl +
      '" type="video/mp4" /><p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noopener noreferrer">supports HTML5 video</a></p></video></div>';
    $.fancybox.open({
      type: "html",
      content: $content,
      beforeShow: function () {
        $("body").addClass("is--videopopup");
        $(".fancybox-container").addClass(videoClass);
      },
      afterShow: function () {
        vidPlayer = videojs("lightboxVideo", function () {
          techOrder;
        });

        vidPlayer.src({
          type:
            videoType === "youtube" ? "video/youtube" :
              videoType === "wistia" ? "video/wistia" :
                videoType === "video/mp4" ? "video/mp4" :
                  videoType === "application/x-mpegURL" ? "application/x-mpegURL" :
                    "application/x-mpegURL",
          src:
            videoType === "youtube"
              ? "https://www.youtube.com/watch?v=" + videoUrl
              : videoType === "wistia"
                ? "http://fast.wistia.com/embed/iframe/" + videoUrl
                : videoUrl,
        });
        if (videoPoster) vidPlayer.poster(videoPoster);
        vidPlayer.on("ready", function () {
          vidPlayer.play();
        });
        $(".popup-video").animate(
          {
            opacity: "1",
          },
          500
        );
      },
      beforeClose: function () {
        $("body").removeClass("is--videopopup");
        videojs("lightboxVideo").dispose();
      },
    });
  }

  function dataSrc() {
    if (winWidth < 600) {
      $("[data-mobile-src]").each(function () {
        var thisSrc = $(this).attr("data-mobile-src");
        $(this).attr("src", thisSrc);
      });
      $("[data-mobile-poster]").each(function () {
        var thisSrc = $(this).attr("data-mobile-poster");
        $(this).attr("poster", thisSrc);
      });
    } else {
      $("[data-desktop-src]").each(function () {
        var thisSrc = $(this).attr("data-desktop-src");
        $(this).attr("src", thisSrc);
      });
      $("[data-desktop-poster]").each(function () {
        var thisSrc = $(this).attr("data-desktop-poster");
        $(this).attr("poster", thisSrc);
      });
    }
  }
  // Scroll timeline block animation gsap
  function scrollTop() {
    $(".header-listing a").click(function () {
      var targetSection = $(this).attr("href");
      $("html,body").animate(
        {
          scrollTop: $(targetSection).offset().top,
        },
        "slow"
      );
    });
    var lastScrollTop = 0;
    $(window).scroll(function () {
      var st = $(this).scrollTop();
      if (st > lastScrollTop) {
        $("body").removeClass("scrollTop");
        $("body").addClass("bodyScrolled");
      } else {
        $("body").addClass("scrollTop");
        $("body").removeClass("bodyScrolled");
      }
      if (st === 0) {
        $("body").removeClass("scrollTop");
        $("body").removeClass("bodyScrolled");
      }

      lastScrollTop = st;
    });
  }

  function separatePopup() {
    var allVideos = document.querySelectorAll(".my-video-js");
    allVideos.forEach((video) => {
      if (video.classList.value.includes("js-bg-video")) {
        return;
      }
      var player = videojs(video);
      player.pause();
    });
  }
  //Pause Viedo on open accordion
  function pauseAccordionVideo($videoElements, $clikedAccordion) {
    if ($videoElements && $videoElements.length > 0) {
      $videoElements.each((index, elem) => {
        var player1 = videojs(elem);
        if (!$clikedAccordion.hasClass("expand")) {
          player1.pause();
        }
      });
    }
  }

  // MileStone Horizontal accordion
  function milestone() {
    if ($(".milestone-box").length) {
      calculateExpandWidth();
      if (window.matchMedia("(max-width: 991px)").matches) {

        $(".js-milestone-box-card-title").click(function () {
          pauseAccordionVideo($(".js-video-milestone"), $(this));
          if ($(this).parent().hasClass("expand")) {
            $(this).parent().removeClass("expand").addClass("small");
          } else {
            $(".milestone-box").removeClass("expand").addClass("small");
            $(this).parent().removeClass("small").addClass("expand");
          }
        });
      } else {
        $(".milestone-box").click(function () {
          pauseAccordionVideo($(".js-video-milestone"), $(this));
          if ($(this).hasClass("expand")) {
            if (window.matchMedia("(max-width: 991px)").matches) {
              $(this).removeClass("expand").addClass("small");
            }
          } else {
            $(".milestone-box").removeClass("expand").addClass("small");
            $(this).removeClass("small").addClass("expand");
          }
        });
      }



      $(".prev-button").click(function (event) {
        event.stopPropagation();
        var $currentAccordion = $(this).closest(".milestone-box");
        var $prevAccordion = $currentAccordion.prev();
        if ($prevAccordion.length > 0) {
          $currentAccordion.removeClass("expand").addClass("small");
          $prevAccordion.removeClass("small").addClass("expand");
        }
        pauseAccordionVideo($(".js-video-milestone"), $currentAccordion);
      });

      $(".next-button").click(function (event) {
        event.stopPropagation();
        var $currentAccordion = $(this).closest(".milestone-box");
        var $nextAccordion = $currentAccordion.next();
        if ($nextAccordion.length > 0) {
          $currentAccordion.removeClass("expand").addClass("small");
          $nextAccordion.removeClass("small").addClass("expand");
        }
        pauseAccordionVideo($(".js-video-milestone"), $currentAccordion);
      });
    }
  }

  function calculateExpandWidth() {
    if (winWidth > 991) {
      var totalSmallDivWidth = 0;
      $(".milestone-box").each(function () {
        totalSmallDivWidth += $(this).width();
      });

      var containerWidth = $(".milestone-wrapper").width();
      var remainingWidth = containerWidth - totalSmallDivWidth;
      document.documentElement.style.setProperty(
        "--width-milestone-wrapper",
        `${remainingWidth}px`
      );
    }
  }

  // Faq Section
  function faqQsAndAnsIds() {
    const faqQs = $(".js-faq-qs"); 
    const faqAns = $(".js-faq-ans");
    const secFaqQs = $(".js-sec-faq-qs");
    const secFaqAns = $(".js-sec-faq-ans");

    if (faqQs && faqQs.length > 0 && faqAns && faqAns.length > 0) {
      if (window.innerWidth > 991) {
        faqQs.each((index, qs) => {
          $(qs).attr("data-value", index);
        });

        faqAns.each((index, ans) => {
          $(ans).attr("data-value", index);
        });

        const heightSecFaqQs = secFaqQs.height();
        secFaqAns.css("maxHeight", `${heightSecFaqQs}px`);

        faqQs.each((_, qs) => {
          qs.addEventListener("click", (e) => {
            faqQs.each((_, qs) => qs.classList.remove("active"));
            qs.classList.add("active");

            faqAns.each((j, ans) => {
              const blockValue = $(ans).data("value");
              const yearValue = $(qs).data("value");

              const videoElements = ans.querySelectorAll(".my-video-js");

              if (videoElements && videoElements.length > 0) {
                videoElements.forEach((elem) => {
                  var player1 = videojs(elem);
                  player1.pause();
                });
              }

              if (blockValue === yearValue) {
                faqAns.each((_, ans) => ans.classList.remove("active"));
                ans.classList.add("active");
              }
            });
          });
        });
      } else {
        const mobileQs = $(".js-mobile-qs");
        if (mobileQs && mobileQs.length > 0) {
          mobileQs.each((j, qs) => {
            $(qs).on("click", (e) => {
              const associatedAns = $(qs).closest(".js-faq-ans");

              const videoElements = $(associatedAns).find(".my-video-js");

              if (videoElements && videoElements.length > 0) {
                videoElements.each(function () {
                  var player1 = videojs(this);
                  player1.pause();
                });
              }

              $(faqAns).not(associatedAns).removeClass("active");
              associatedAns.toggleClass("active");
            });
          });
        }
      }
    }
  }

  //Slide Count Function
  function slideCount(slideElements, i, key) {
    var totalSlides = slideElements.slick("getSlick").slideCount;
    var currentSlide = slideElements.slick("slickCurrentSlide");

    updateSlideCount(currentSlide, totalSlides);

    slideElements.on("afterChange", function (event, slick, currentSlide) {
      updateSlideCount(currentSlide, totalSlides);
    });

    function updateSlideCount(current, total) {
      var currentSlideText = current + 1 < 10 ? "0" + (current + 1) : current + 1;
      var totalSlidesText = total < 10 ? "0" + total : total;
      $(`.js-slide-count-${key}-${i}`).html(
        `<span class="current-slide"> ${currentSlideText} </span> / ${totalSlidesText}`
      );
    }
  }

  // Slick Slider with slide-count
  function slickSliderWithCounter() {
    if ($(".js-slider-heighlight").length) {
      $(".js-slider-heighlight").each(function (i, e) {
        var $slider = $(this).find(".js-slider-heighlight-wrapper");
        var key = "heighlight";
        $(this).find(".js-slide-arrow").addClass(`js-slide-arrow-${key}-${i}`);
        $(this).find(".js-slide-count").addClass(`js-slide-count-${key}-${i}`);
        $slider.slick({
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          prevArrow: bannerPrev,
          nextArrow: bannerNext,
          appendArrows: `.js-slide-arrow-${key}-${i}`,
          rtl: isRTL,
          draggable: true,
          dots: true,
          // swipe: false,
          adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                dots: true,

              },
            },
          ],
        });

        slideCount($slider, i, key);
      });
    }
  }

  // Slick Slider with slide-count
  function partnerSlider() {
    if ($(".js-slider-partner").length) {
      $(".js-slider-partner").each(function (i, e) {
        var $slider = $(this).find(".js-slider-partner-wrapper");
        var key = "partner";
        $(this).find(".js-slide-arrow").addClass(`js-slide-arrow-${key}-${i}`);
        $(this).find(".js-slide-count").addClass(`js-slide-count-${key}-${i}`);
        $slider.slick({
          // infinite: false,
          slidesToShow: 7,
          slidesToScroll: 1,
          prevArrow: bannerPrev,
          nextArrow: bannerNext,
          appendArrows: `.js-slide-arrow-${key}-${i}`,
          rtl: isRTL,
          variableWidth: true,
          draggable: true,
          swipe: true,
          autoplay: true,
          autoplaySpeed: 2000,
          swipeToSlide: true,
          touchThreshold: 200,
          responsive: [
            {
              breakpoint: 1681,
              settings: {
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 601,
              settings: {
                slidesToShow: 2,
              },
            },
          ],
        });

        slideCount($slider, i, key);
      });
    }
  }

  // Slick Slider with slide-count
  function testimonialSlider() {
    if ($(".js-slider-testimonial").length) {
      $(".js-slider-testimonial").each(function (i, e) {
        var $slider_for = $(this).find(".testimonial-for");
        var $slider_nav = $(this).find(".testimonial-nav");
        var key = `testimonial`;
        $(this).find(".js-slide-arrow").addClass(`js-slide-arrow-${key}-${i}`);
        $(this).find(".js-slide-count").addClass(`js-slide-count-${key}-${i}`);
        $slider_for.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
          rtl: isRTL,
          asNavFor: ".testimonial-nav",
          draggable: false,
          swipe: false,
          infinite: false,
          adaptiveHeight: true,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                adaptiveHeight: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                dots: true,
                draggable: true,
              },
            },
          ],
        });
        $slider_nav.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          asNavFor: ".testimonial-for",
          dots: false,
          prevArrow: bannerPrev,
          nextArrow: bannerNext,
          appendArrows: `.js-slide-arrow-${key}-${i}`,
          draggable: false,
          swipe: false,
          rtl: isRTL,
          infinite: false,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                adaptiveHeight: true,
              },
            },
          ],
        });

        slideCount($slider_nav, i, key);
      });
    }
  }

  // Slick Slider with slide-count
  function drawerSlider() {
    if ($(".drawer-wrapper").length) {
      $(".drawer-wrapper").each(function (i, e) {
        var $slider = $(this).find(".js-slider-drawer-wrapper");
        var key = "drawer";
        $(this).find(".js-slide-arrow").addClass(`js-slide-arrow-${key}-${i}`);
        $(this).find(".js-slide-count").addClass(`js-slide-count-${key}-${i}`);
        $slider.slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: bannerPrev,
          nextArrow: bannerNext,
          appendArrows: `.js-slide-arrow-${key}-${i}`,
          rtl: isRTL,
          fade: true,
          // draggable: false,
          // swipe: false,
          responsive: [
            {
              breakpoint: 601,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        });
        slideCount($slider, i, key);
      });
    }
  }

  // On Drawer open Goto Slide
  function goToSlide($sliderElement, slideIndex) {
    $sliderElement.slick("slickGoTo", slideIndex);
  }

  // Adds a class when swipping...
  $('.js-slider-partner-wrapper, .js-slider-management-wrapper').off('swipe').on('swipe', function (event, slick, direction) {
    $('.slick-slider').addClass('swipping');
  });

  // Remove tha class after swipe was done, AND delayed of ~100 ms
  $('.js-slider-partner-wrapper, .js-slider-management-wrapper').off('afterChange').on('afterChange', function (event, slick, currentSlide, nextSlide) {
    setTimeout(function () { $('.slick-slider').removeClass('swipping'); }, 110);
  });

  $('.js-slider-partner-wrapper, .js-slider-drawer-wrapper').off('swipe').on('swipe', function (event, slick, currentSlide, nextSlide) {

    const activeDrawerBody = document.querySelector('.drawer-body.slick-active')
    const activeDrawerContent = activeDrawerBody.querySelector('.drawer-content .mCSB_container');

    // activeDrawerContent.style.top = 0
  });


  // Custom Drawer

  function drawer() {
    const $jsDrawers = $(".js-drawer");
    const $jsDrawersClose = $(".js-drawer-close");
    const $jsBody = $("body");
    const $jsHTML = $("html");
    const $jsSliderElem = $(".js-slider-drawer-wrapper");
    
    if ($jsDrawers.length) {
      $jsDrawers.click(function () {
        if ($jsDrawers.parents('.swipping').length) {
          // Handle swiping case if necessary
        } else {
          const drawerId = $(this).data("drawer-id");
          const parentElem = $(this).closest(".slick-slide");
          
          if (parentElem.length) {
            const slideIndex = parentElem.attr("data-slick-index");
            goToSlide($jsSliderElem, slideIndex);
          }
          
          $(`[data-drawer-id="${drawerId}"]`).each(function () {
            const element = $(this);
            if (element.hasClass("js-drawer-wrapper")) {
              element.addClass("popup--show");
            }
          });

          $jsBody.addClass("overflow-y-hidden");
          $jsHTML.addClass("overflow-y-hidden");
        }
      });

      $jsDrawersClose.click(function () {
        const drawerId = $(this).data("drawer-id");
        const jsSlideParterSlickSlide = document.querySelectorAll('.js-slider-drawer-wrapper .slick-slide');
        
        jsSlideParterSlickSlide.forEach(jsSlideParterSlickSlide => {
          const activeDrawerContent = jsSlideParterSlickSlide.querySelector('.drawer-content .mCSB_container');
          // activeDrawerContent.style.top = 0
        });
        
        $(`[data-drawer-id="${drawerId}"]`).removeClass("popup--show");
        
        if (!$(".popup--show")[0]) {
          $jsBody.removeClass("overflow-y-hidden");
          $jsHTML.removeClass("overflow-y-hidden");
        }
        // $(".drawer-body").mCustomScrollbar("scrollTo", "top");
      });

      // Close drawer when clicking outside of it
      $(document).on("click", function (event) {
        const isDrawerOpenBtn = $(event.target).closest(".js-drawer").length > 0;
        const isDrawerContainer = $(event.target).closest(".js-drawer-wrapper, .drawer-contentBox").length > 0;

        if (!isDrawerOpenBtn && !isDrawerContainer) {
          $(".js-drawer-wrapper").removeClass("popup--show");
          $jsBody.removeClass("overflow-y-hidden");
          $jsHTML.removeClass("overflow-y-hidden");
        }
      });

      // Prevent click event inside the drawer from closing it
      $(".js-drawer-wrapper").on("click", function (event) {
        event.stopPropagation();
      });

      // Add event listener to close the drawer on Escape key press
      $(document).on("keydown", function (event) {
        if (event.key === "Escape") {
          $(".js-drawer-wrapper").removeClass("popup--show");
          $jsBody.removeClass("overflow-y-hidden");
          $jsHTML.removeClass("overflow-y-hidden");
        }
      });

      ChangeToSvg();
    }
  }
  function sectionAnim() {
    $(".secAnimate").each(function () {
      gsap.fromTo(
        this,
        {
          y: 200,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          ease: "power2.out",
          duration: isMobile767 ? 0.5 : 1.5,
          delay: isMobile767 ? 0 : 0.5,
          scrollTrigger: {
            trigger: this,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    });
  }
  function sectionAnimVideo() {
    $(".secAnimateVideo").each(function () {
      gsap.fromTo(
        this,
        {
          y: 200,
          opacity: 0,
          transform: "scale(0.9)",
        },
        {
          y: 0,
          opacity: 1,
          transform: "scale(1)",
          delay: 0.3,
          ease: "power2.out",
          duration: 1,
          scrollTrigger: {
            trigger: this,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    });
  }

  $(document).ready(function () {
    function messageNotification() {
      $(".closeNotification").click(function () {
        $(".notificationBox").fadeOut(300);

      });
    }
    messageNotification();
  });

  $(document).ready(function () {
    const banner = $('#cookie-banner');
    const acceptButton = $('#accept-cookies');
    const rejectButton = $('#reject-cookies');
    if (!localStorage.getItem('cookieAccepted')) {
      banner.show();
    }
    acceptButton.on('click', function () {
      banner.fadeOut(300);
      localStorage.setItem('cookieAccepted', 'true');
    });
    rejectButton.on('click', function () {
      banner.fadeOut(300);
    });

  });

  // Close video news detail

  function closeVideoOnClick() {
    const newsDetailBox = document.querySelector('.news-detail-box')
    const closeIc = newsDetailBox?.querySelector('.js-vid-close-icon')
    const videoJsDiv = newsDetailBox?.querySelector('.my-video-js')
    // vjs-playing vjs-has-started
    const videoJsTechDiv = newsDetailBox?.querySelector('.vjs-tech')
    const videoJsControls = videoJsDiv?.querySelector('.vjs-control-bar')
    const videoJsPoster = videoJsDiv?.querySelectorAll('.vjs-poster')
    let videoPlayer;
    if (videoJsTechDiv) {

      videoPlayer = videojs(videoJsTechDiv);
    }
    closeIc?.addEventListener('click', () => {

      closeIc.classList.remove('show-icon')
      videoPlayer.currentTime(0);
      videoPlayer.pause();
      videoJsPoster.forEach(poster => {
        poster.style.display = 'block';
        videoJsControls.style.display = 'none';
        videoJsControls.style.display = 'none';
      })
    })

    if (videoPlayer) {
      videoPlayer.on('play', () => {
        const videoJsPoster = videoJsDiv?.querySelectorAll('.vjs-poster')
        closeIc.classList.add('show-icon')
        videoJsPoster.forEach(poster => {
          poster.style.display = 'none';
          videoJsControls.style.display = 'flex';
        })
      });
    }
  }

  function initializeCustomScrollbar() {
    if(winWidth > 600) {
      $('.custom-scroll').mCustomScrollbar()
    }
    else {
      $(selector).mCustomScrollbar('destroy');
    }
  }

  $(document).ready(function () {
    initializeCustomScrollbar();
  });

  $(window).resize(function () {
    initializeCustomScrollbar();
  });

  function messageCharacterCount() {
    const textarea = $("textarea");
  
    if (textarea.length > 0) {
      function updateCharacterCount() {
        const currentCount = textarea.val().length;
  
        const charCount = $(".js-char-count");
  
        if (charCount.length > 0) {
          charCount.text(`${currentCount}/700`);
        }
      }
  
      textarea.on("keydown keyup", updateCharacterCount);
    }
  }

  // ********   Data Layer Start   **********

  var count = 0;
  function show(e) { console.log(e) }

  function pushToDataLayer(eventCategory, eventAction, eventLabel, event) {

    dataLayer.push({
      // "event": event,
      "eventAction": eventAction,
      "eventCategory": eventCategory,
      "eventLabel": eventLabel,
      // "page_url": window.location.href
    })
  }

  function addCTAClickListener(element) {
    if (element === undefined) return;
    if (element.innerHTML === undefined) {
      $(element).each(function (i, e) {

        if ($(e).is('a')) { // Is <a>

          // skip social outbound links
          if ($(e).find('img').length) return true;

          // Is <a> & main-primary-btn 
          if ($(e).hasClass('main-primary-btn')) {
            const CTAName = $(e).find('.btn-text')[0].innerHTML;
            const CTALink = e.href;
            if (CTAName) {
              count++;
              $(e).on('click', () => {
                pushToDataLayer(CTAName, "CTA_Click", CTALink, CTAName);
              })
            }
          } else { // Is <a> ! main-primary-btn ! has <img/>
            const CTAName = e.innerHTML;
            const CTALink = e.href;
            count++;
            $(e).on('click', function () {
              pushToDataLayer(CTAName, "CTA_Click", CTALink, CTALink);
            })
          }
        } else if ($(e).is('button') && $(e).find('.btn-text')) { // Is <button> & has .btn-text
          const CTAName = $(e).find('.btn-text')[0].innerHTML;
          count++;
          $(e).on('click', () => {
            pushToDataLayer(CTAName, "CTA_Click", CTAName, CTAName);
          })
        }
      })
    } else {
      if ($(element).find('img').length) return; // skip social outbound links

      const CTAName = element.innerHTML;
      const CTALink = element.href;
      count++;
      $(element).on('click', function () {
        pushToDataLayer(CTAName, "CTA_Click", CTALink, CTALink);
      })
    }
  }

  function addOutboundLinkClickListener(elements) {
    $(elements).each((i, e) => {
      if ($(e).find('img').length) {
        count++;
        $(e).on('click', () => {
          pushToDataLayer('outbound_link', 'link_click', e.href)
        })
      } else if ($(e).hasClass('main-primary-btn') && $(e).attr('id') !== 'submit') {
        count++;
        $(e).on('click', () => {
          pushToDataLayer('outbound_link', 'link_click', e.href)
        })
      } else { show('Found a new type of outbound link!') }
    });
  }

  function onPageViewPushDataLayer() {
    count++;
    dataLayer.push({
      "event": "page_view",
      "page_url": window.location.href,
    });
  }

  function addVideoEventsListener(elements) {
    function getName(url) {
      var parts = url.split('/');
      return parts[parts.length - 1];
    }

    if ($(elements).is('video')) {
      $(elements).each((i, e) => {
        if (e.id === "my-video-1" || e.id === "banner_video") { return true }
        e.firstPlay = true;
        $(e).on('play', () => {
          if (e.firstPlay) {
            e.title = getName(e.src);
            pushToDataLayer("video_event", "video_play", e.title, "video_plays")
            e.firstPlay = false
          }
        })
        if (e.loop) {
          e.isCompletedOnce = false;
          $(e).on('timeupdate', () => {
            if (e.currentTime > e.duration - 0.5 && !e.isCompletedOnce) {
              e.title = getName(e.src);
              e.isCompletedOnce = true
              pushToDataLayer("video_event", "video_complete", e.title, "video_complete")
            }
          })
        } else {
          $(e).on('ended', () => {
            e.title = getName(e.src);
            pushToDataLayer("video_event", "video_complete", e.title, "video_complete")
          })
        }

      })
    } else {
      $(elements).on('click', () => {
        var video;
        setTimeout(() => {
          video = $('#lightboxVideo_html5_api');
          addVideoEventsListener(video);
          $(video)[0].pause();
          $(video)[0].play();
        }, 2000)
      });
    }
  }

  function addSliderCardClickListener(elements) {
    $(elements).each((i, e) => {
      if ($(e).find('.hlsi-info').length) {
        const text = $(e).find('.hlsi-info .body2')[0].innerHTML;
        $(e).on('click', () => {
          pushToDataLayer(text, "click", text, "highlights_tiles");
        })
      } else if ($(e).find('.partner-logo').length) {
      }
    })
  }

  function addDownloadClickListener(elements) {
    $(elements).each((i, e) => {
      if ($(e).find('.btn-text').length) {
        const text = $(e).find('.btn-text')[0].innerHTML;
        count++;
        $(e).on('click', () => {
          pushToDataLayer("file_download", "download", text);
        })
      }
    })
  }

  function addFormEventsListener(elements, name, pageTitle) {
    $(elements).each((i, e) => {
      if ($(e).is('form')) {
        if (document.title === pageTitle) {
          count++;
          $(e).one('input', 'input, textarea, select', function () {
            pushToDataLayer('form_event', 'form_start', name);
          });
          count++;
          $(e).on('submit', function () {
            pushToDataLayer('form_event', 'form_submit', name);
          });
        }
      } else { show('Not a form!') }
    })
  }

  function handleDataLayer() {
    // -header.html (9 elements)
    addCTAClickListener($('.header .header-logo a'));
    addCTAClickListener($('.header .mbl--menu-wrapper a'));
    addCTAClickListener($('.header .menu-link a'));
    addCTAClickListener($('.header .lag-switch a'));
    // -banner.html
    addCTAClickListener($('.banner-home .main-primary-btn'));
    // -our-vision.html
    addCTAClickListener($('.sec-vision .main-primary-btn'));
    // -heighlights.html & -latest-updates.html
    addCTAClickListener($('.slider--sec .main-primary-btn'));
    // -key-milestone.html
    addCTAClickListener($('.sec-milestone .main-primary-btn'));
    // -key-facts.html
    addCTAClickListener($('.key-facts-sec .main-primary-btn'));
    // -footer.html (6 elements)
    addCTAClickListener($('.footer-section .main-primary-btn'));
    addCTAClickListener($('.footer-section .main-secondary-btn'));
    addCTAClickListener($('.cookie-banner-wrap .btn-link'));
    addCTAClickListener($('.cookie-banner-wrap .main-primary-btn'));
    addCTAClickListener($('.footer-section .footer-bottom a'));
    // -timeline.html
    addCTAClickListener($('.sec-timeline a'));
    addCTAClickListener($('.sec-testimonial .sec-padded .main-primary-btn'));
    // -sec-press.html
    addCTAClickListener($('.sec-press .post-box .main-primary-btn'));
    // -contact-us.html
    addCTAClickListener($('.contact-us .contact-us-flex-left a'));
    // -news-and-media-filters.html
    addCTAClickListener($('.news-and-media-wrapper .main-primary-btn'));
    // -terms-and-conditions.html
    addCTAClickListener($('.terms-and-conditions-wrap a'));
    // -404-content.html
    addCTAClickListener($('.error--page .main-primary-btn'));
    // ***************************** < CTA ********************************************

    // *************************** Download > *****************************************
    addDownloadClickListener($('.timeline.drawer-wrapper .main-primary-btn'));
    // *************************** < Download *****************************************

    // *********************** Outbound > *********************************************
    addOutboundLinkClickListener('.social a');
    addOutboundLinkClickListener('.contact-us .contact-us-flex-left .main-primary-btn');
    // *********************** < Outbound *********************************************

    // ************************* Form > ***********************************************
    addFormEventsListener($('.contact-us .contact-us-flex-right form'), 'careers', 'Careers');
    addFormEventsListener($('.contact-us .contact-us-flex-right form'), 'contact_us', 'Contact Us');
    // ************************* < Form ***********************************************

    // ************************* Video > **********************************************
    addVideoEventsListener($('video'));
    addVideoEventsListener($('.video-banner-btn'));
    // ************************* < Video **********************************************
  }
  // ********   Data Layer End   ************

  function faqRedirect() {
    const faqLink = document.querySelector('.js-faq');
    const faqSectionId = $(faqLink).attr('href');
    const headerHeight = 200;
  
    function smoothScrollTo(element, duration) {
        const start = window.scrollY || window.pageYOffset;
        const end = element.getBoundingClientRect().top + start - headerHeight;
        const distance = end - start;
        let startTime = null;
  
        function scrollStep(timestamp) {
            if (!startTime) startTime = timestamp;
            const progress = timestamp - startTime;
            const scrollAmount = Math.min(progress / duration, 1) * distance;
            window.scrollTo(0, start + scrollAmount);
            if (progress < duration) requestAnimationFrame(scrollStep);
        }
        requestAnimationFrame(scrollStep);
    }
  
    if (faqLink) {
        faqLink.addEventListener('click', (event) => {
            event.preventDefault();
            $('body').removeClass('menu--open');
            const currentPath = window.location.pathname;
            
            let langPath = '/';
            if (currentPath.startsWith('/ar/') || currentPath === '/ar') {
              langPath = '/ar/';
            }
            const isEnHome = currentPath === '/en/' || currentPath === '/en' || currentPath === '/';
            const isArHome = currentPath === '/ar/' || currentPath === '/ar';
            const isHomePage = isEnHome || isArHome;
  
            const homeUrlEn = `${window.location.origin}${faqSectionId}`;
            const homeUrlAr = `${window.location.origin}${'/ar'}${faqSectionId}`;
            
            if (!isHomePage) {
              $('body').removeClass('menu--open');
              if(langPath === '/ar/') {
                window.location.href = homeUrlAr;
              } else {
                window.location.href = homeUrlEn;
              }
            } else {
              const faqSection = document.getElementById(faqSectionId.split('#')[1]);
                if (faqSection) smoothScrollTo(faqSection, 1000);
            }
        });
    }
  
    if (window.location.hash) {
        const targetId = window.location.hash.substring(1);
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            setTimeout(() => smoothScrollTo(targetElement, 1000), 100);
        }
    }
  }